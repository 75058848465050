@font-face {
font-family: '__Sans_NoTo_Sans_CJK_KR_17beb1';
src: url(/_next/static/media/d7493ecfce47afd6-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 400;
font-style: normal;
}

@font-face {
font-family: '__Sans_NoTo_Sans_CJK_KR_17beb1';
src: url(/_next/static/media/3dcc7e1c4279c8c2-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 500;
font-style: normal;
}

@font-face {
font-family: '__Sans_NoTo_Sans_CJK_KR_17beb1';
src: url(/_next/static/media/86c1735b605be313-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 700;
font-style: normal;
}@font-face {font-family: '__Sans_NoTo_Sans_CJK_KR_Fallback_17beb1';src: local("Arial");ascent-override: 110.24%;descent-override: 27.37%;line-gap-override: 0.00%;size-adjust: 105.22%
}.__className_17beb1 {font-family: '__Sans_NoTo_Sans_CJK_KR_17beb1', '__Sans_NoTo_Sans_CJK_KR_Fallback_17beb1'
}.__variable_17beb1 {--font-noto-sans-cjk-kr: '__Sans_NoTo_Sans_CJK_KR_17beb1', '__Sans_NoTo_Sans_CJK_KR_Fallback_17beb1'
}

